<template>
  <div class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
      <div class="col-lg-5 col-md-8 py-8 py-xl-0">
        <div class="card shadow">
          <div class="card-body p-6">
            <div class="mb-4 text-center">
              <router-link :to="{name: 'Home'}" v-if="school.thumbnail">
                <img :src="school.thumbnail.link" class="mb-4 avatar-xxl rounded bg-white object-fit-cover" alt="">
              </router-link>
              <h1 class="mb-1 fw-bold">Đổi mật khẩu</h1>
              <span>Bạn đã có tài khoản?&nbsp;
                 <router-link :to="{name: 'Login'}" class="ms-1">Đăng nhập</router-link>
              </span>
            </div>
            <form v-on:submit.prevent="changePassword" autocomplete="off">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label" for="password">Nhập mật khẩu mới của bạn:</label>
                    <input type="text" v-model="password" id="password" class="form-control" name="password" placeholder="Mật khẩu mới"
                           required />
                  </div>
                </div>
              </div>
              <div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary" :disabled="disabled">
                    Đổi mật khẩu
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="changePasswordModal" title="Quên mật khẩu" :type="messageModalType" :message="messageModalMessage" />
</template>

<script>
import ApiService from "@/core/services/api.service";
import Modal from "@/components/Modal";
import {openModal} from "@/core/services/utils.service";

export default {
  name: 'ForgotPassword',
  props: {
    school: Object
  },
  components: {
    Modal
  },
  data() {
    return {
      password: "",
      messageModalType: "",
      messageModalMessage: "",
      disabled: false,
      type: ""
    }
  },
  methods: {
    changePassword() {
      this.disabled = true;
      this.messageModalTitle = "Đổi mật khẩu";

      let query = `mutation($password: String!) {
        changePassword(password: $password)
      }`;

      ApiService.graphql(query, {password: this.password})
          .then(({data}) => {
            if (data.data && data.data.changePassword) {
              this.messageModalMessage = "Đổi mật khẩu thành công. Bạn vui lòng đăng nhập lại";
              this.messageModalType = "success";
              openModal("changePasswordModal");
              this.$router.push({name: "Login"});
            } else {
              this.disabled = false;
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("changePasswordModal");
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("changePasswordModal");
          });
    }
  }
}
</script>
